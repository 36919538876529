// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'es',
    brand: 'bk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: '356f497d7b11259d869b186a3114e23f0ba29a4f',
    releaseTagRef: '9400cbf',
    releaseTimestamp: '1734176006',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    adyen: {
      clientKey: '',
      environment: '',
      urlPrefix: '',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '8d0bbe09383b17c453a4f59359b70c4a',
      branch: '',
      braze: 'd007e721-4f1f-452f-8569-db35d9bc9a9d',
      brazeCluster: 'sdk.fra-02.braze.eu',
      bringg: '',
      googleMaps: 'AIzaSyBPOBpQKtGjU7lC6cyY2GyNgaHAgQXyAm8',
      tomtom: '',
      launchDarkly: '644687504b9d9412dd2dc5d0',
      launchDarklyMobileKey: 'mob-3b89f577-5043-4cb2-8548-0359bf41cb4a',
      mParticle: 'eu1-0c13f335900287438fd4103e7b18d850',
      cookiebot: '',
      bloomreachTarget: '',
      bloomreachProjectToken: '',
      amplitudeCdpKey: '',
      amplitudeCdpRegion: '',
    },
    apple: {
      merchantId: '',
      migrationMerchantId: '',
      paymentsNetworks: [],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: 'com.bk.es.sociallogin',
        redirectURI: '',
      },
    },
    branch: {
      domains: []
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://euw3-prod-bk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euw3-prod-bk-gateway.rbictg.com/graphql',
      region: 'eu-west-3',
      userPoolClientId: '2a060kij81jvfh0tc83jsh2pi',
      userPoolId: 'eu-west-3_GvXaK8SsD',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: 'idNotSet',
    },
    google: {
      paymentsEnvironment: '',
      paymentsGateway: '',
      marketPaymentsGatewayMerchantId: '',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: [],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '1069624710676-bet2u2qi45p6e84spd8pkbe8fsb9dgt2.apps.googleusercontent.com',
        redirectURI: 'https://bk-es-e023d.firebaseapp.com/__/auth/handler',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: 'GTM-WHRJD68',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: 'C0003',
        consentCategoryMParticle: 'C0001',
        globalConsentState: 'Opt-In',
        scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        scriptId: 'c03023e3-b6a7-42c4-a6d2-586d18506355',
      },
      deleteAccountUrl: 'https://privacyportal-de.onetrust.com/webform/67b80636-41d6-4b37-aea3-717da0a9526f/draft/1e2b95e3-8c4e-42a8-8d8f-92d6e6b4cbea',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: '',
      firstData: 'https://api-int.payeezy.com',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: 'HH:mm',
    dateFormat: 'dd/MM/yyyy',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'oneTrust',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: '',
          consentCategoryBraze: 'C0003',
          consentCategoryMParticle: 'C0001',
          consentCategoryDdRum: '',
          globalConsentState: 'Opt-In',
          scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          scriptId: '019223c0-00c9-7df2-8c6e-f194c87a9788',
        },
      },
    }
  };